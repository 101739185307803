// FormStyle1.jsx Formulario de contacto
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Función para obtener los parámetros UTM de la URL
const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_campaign: params.get('utm_campaign') || '',
    utm_term: params.get('utm_term') || '',
    utm_content: params.get('utm_content') || '',
  };
};

// Función para obtener la dirección IP del usuario
const fetchUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || '';
  } catch (error) {
    console.error('Error obteniendo la IP:', error);
    return '';
  }
};

const FormStyle1 = () => {
  const { t, i18n } = useTranslation();

  const [additionalFields, setAdditionalFields] = useState({
    idioma: i18n.language || 'es',
    urlOrigen: window.location.href,
    ipUsuario: '',
    ...getUTMParameters(),
  });

  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'floating',
    form_name_id: '1',       // Reemplaza con el valor real
    campaign_origin_id: '',
    origin_id: '8',
    lead_type_id: '3',
    market_id: '9',            // Reemplaza con el valor real
  };

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    politica: false,
    ...hiddenFields,
    ...additionalFields,
  });

  const [message, setMessage] = useState({ type: '', text: '' });

  useEffect(() => {
    const getIP = async () => {
      const ip = await fetchUserIP();
      setAdditionalFields((prev) => ({ ...prev, ipUsuario: ip }));
      setFormData((prev) => ({ ...prev, ipUsuario: ip }));
    };
    getIP();
  }, []);

  useEffect(() => {
    setAdditionalFields((prev) => ({ ...prev, idioma: i18n.language }));
    setFormData((prev) => ({ ...prev, idioma: i18n.language }));
  }, [i18n.language]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      telefono: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new URLSearchParams();

    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch('https://dashboard.gestlifesurrogacy.com/formularios/formStyle1.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        try {
          const result = await response.json();
          setMessage({ type: 'success', text: result.message || 'Formulario enviado con éxito.' });
        } catch (parseError) {
          console.error('Error al parsear JSON:', parseError);
          setMessage({ type: 'success', text: 'Formulario enviado con éxito.' });
        }

        setFormData({
          nombre: '',
          email: '',
          telefono: '',
          politica: false,
          ...hiddenFields,
          ...additionalFields,
        });
      } else {
        let errorText = 'Error al enviar el formulario.';
        try {
          const errorResponse = await response.json();
          errorText = errorResponse.message || errorText;
        } catch (parseError) {
          console.error('Error al parsear JSON de error:', parseError);
        }
        setMessage({ type: 'error', text: errorText });
      }
    } catch (error) {
      console.error('Error de red:', error);
      setMessage({ type: 'error', text: 'Error de red al enviar el formulario.' });
    }
  };

  return (
    <div className="form-style-1-container">
      <form onSubmit={handleSubmit} className="form-style-1">
        {/* Campos ocultos básicos */}
        {Object.keys(hiddenFields).map((key) => (
          <input
            key={key}
            type="hidden"
            name={key}
            value={hiddenFields[key]}
          />
        ))}

        {/* Campos ocultos adicionales */}
        {Object.keys(additionalFields).map((key) => (
          <input
            key={key}
            type="hidden"
            name={key}
            value={additionalFields[key]}
          />
        ))}

        {/* Form Header */}
        <div className="form-header">
          <h3>{t('quieres')}</h3>
          <p>{t('ayudamos')}</p>
        </div>

        {/* Mostrar mensaje de éxito o error */}
        {message.text && (
          <div>
            <strong>{message.type === 'success' ? 'Éxito: ' : 'Error: '}</strong>
            {message.text}
          </div>
        )}

        {/* Form Body */}
        <div className="form-body">
          {/* Nombre Apellidos */}
          <div className="form-group">
            <input
              type="text"
              name="nombre"
              required
              value={formData.nombre}
              onChange={handleChange}
              className="form-control"
              placeholder={t('nombre')}
            />
          </div>

          {/* Correo Electrónico */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder={t('email')}
            />
          </div>

          {/* Teléfono */}
          <div className="form-group phone-input">
            <PhoneInput
              country={'es'}
              value={formData.telefono}
              onChange={handlePhoneChange}
              inputClass="form-control telefono-input"
              enableSearch={true}
              searchPlaceholder={t('telefono')}
            />
          </div>

          {/* Aceptación de Política de Privacidad */}
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              name="politica"
              required
              checked={formData.politica}
              onChange={handleChange}
            />
            <label htmlFor="politica">
              {t('politica')}
            </label>
          </div>

          {/* Botón de Envío */}
          <div className="form-group submit-group">
            <button type="submit" className="submit-button">
              {t('solicitar')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormStyle1;
