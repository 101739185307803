// FormStyle5.jsx Formulario Contacto via Correo
import React, { useState, useEffect } from 'react'; // Import useEffect
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Function to get UTM parameters from the URL
const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_campaign: params.get('utm_campaign') || '',
    utm_term: params.get('utm_term') || '',
    utm_content: params.get('utm_content') || '',
  };
};

// Function to fetch user's IP address
const fetchUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || '';
  } catch (error) {
    console.error('Error obtaining IP address:', error);
    return '';
  }
};

const GestlifeForm = () => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    poblacion: '',
    tipoPareja: '',
    motivoNoHijos: '',
    preferenciaPais: '',
    paisPreferido: '',
    tiempoInicio: '',
    comoNosConocio: '',
    observaciones: '',
    politicaDatos: false,
    // Additional fields
    idioma: i18n.language || '',
    urlOrigen: document.referrer || window.location.href,
    ipUsuario: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  });

  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'comprehensive',
    form_name_id: 'gestlife_inquiry',
    origin_id: '8',
    lead_type_id: '3',
  };

  useEffect(() => {
    // Fetch IP address and UTM parameters on component mount
    const fetchData = async () => {
      const ip = await fetchUserIP();
      const utmParams = getUTMParameters();

      setFormData(prevData => ({
        ...prevData,
        ipUsuario: ip,
        ...utmParams,
      }));
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Update language if it changes
    setFormData(prevData => ({
      ...prevData,
      idioma: i18n.language || '',
    }));
  }, [i18n.language]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      telefono: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new URLSearchParams();

    // Combine form data with hidden fields
    const combinedData = { ...formData, ...hiddenFields };

    for (const key in combinedData) {
      data.append(key, combinedData[key]);
    }

    try {
      const response = await fetch('https://dashboard.gestlifesurrogacy.com/formularios/formStyle5.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        console.log('Formulario enviado con éxito');
        // Reset form after successful submission
        setFormData({
          nombre: '',
          email: '',
          telefono: '',
          poblacion: '',
          tipoPareja: '',
          motivoNoHijos: '',
          preferenciaPais: '',
          paisPreferido: '',
          tiempoInicio: '',
          comoNosConocio: '',
          observaciones: '',
          politicaDatos: false,
          // Reset additional fields
          idioma: i18n.language || '',
          urlOrigen: document.referrer || window.location.href,
          ipUsuario: '',
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
          utm_term: '',
          utm_content: '',
        });
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  const tiposPareja = [
    'Pareja heterosexual casada',
    'Pareja heterosexual NO casada',
    'Mujer soltera',
    'Hombre soltero',
    'Pareja homosexual femenina casada',
    'Una pareja homosexual femenina NO casada',
    'Pareja homosexual masculina casada',
    'Una pareja homosexual masculina NO casada'
  ];

  const paises = [
    'Ucrania', 'Georgia', 'Albania', 'Grecia', 'Colombia', 
    'México', 'Armenia', 'Kazajistán', 'Estados Unidos', 
    'Argentina', 'República Checa', 'Eslovaquia', 
    'Chipre', 'Uzbekistán'
  ];

  const tiemposInicio = [
    'Dentro de los próximos 2 meses',
    'Entre 3 y 6 meses',
    'Entre 6 meses a un año',
    'En un año o año y medio',
    'En un año y medio o dos',
    'Más de dos años'
  ];

  return (
    <div className="gestlife-form-container">
      <form onSubmit={handleSubmit} className="gestlife-form">
        {/* Hidden Fields */}
        <input type="hidden" name="idioma" value={formData.idioma} />
        <input type="hidden" name="urlOrigen" value={formData.urlOrigen} />
        <input type="hidden" name="ipUsuario" value={formData.ipUsuario} />
        <input type="hidden" name="utm_source" value={formData.utm_source} />
        <input type="hidden" name="utm_medium" value={formData.utm_medium} />
        <input type="hidden" name="utm_campaign" value={formData.utm_campaign} />
        <input type="hidden" name="utm_term" value={formData.utm_term} />
        <input type="hidden" name="utm_content" value={formData.utm_content} />

        <div className="form-header">
          <h3>Información para Gestlife</h3>
          <p>Por favor, complete el siguiente formulario</p>
        </div>

        <div className="form-body">
          {/* Nombre */}
          <div className="form-group">
            <input
              type="text"
              name="nombre"
              required
              value={formData.nombre}
              onChange={handleChange}
              className="form-control"
              placeholder="Nombre y Apellidos"
            />
          </div>

          {/* Correo Electrónico */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder="Correo Electrónico"
            />
          </div>

          {/* Teléfono */}
          <div className="form-group phone-input">
            <PhoneInput
              country={'es'}
              value={formData.telefono}
              onChange={handlePhoneChange}
              inputClass="form-control telefono-input"
              enableSearch={true}
              searchPlaceholder="Teléfono móvil"
            />
          </div>

          {/* Población */}
          <div className="form-group">
            <input
              type="text"
              name="poblacion"
              value={formData.poblacion}
              onChange={handleChange}
              className="form-control"
              placeholder="Población"
            />
          </div>

          {/* Tipo de Pareja */}
          <div className="form-group">
            <select
              name="tipoPareja"
              value={formData.tipoPareja}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Seleccione su tipo de pareja</option>
              {tiposPareja.map((tipo, index) => (
                <option key={index} value={tipo}>{tipo}</option>
              ))}
            </select>
          </div>

          {/* Motivo No Hijos */}
          <div className="form-group">
            <textarea
              name="motivoNoHijos"
              value={formData.motivoNoHijos}
              onChange={handleChange}
              className="form-control"
              placeholder="Indique el motivo por el cual no pueden ser padres"
            />
          </div>

          {/* Preferencia País */}
          <div className="form-group">
            <label>¿Tiene alguna preferencia por un país en concreto?</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="preferenciaPais"
                  value="Si"
                  checked={formData.preferenciaPais === 'Si'}
                  onChange={handleChange}
                />
                Sí
              </label>
              <label>
                <input
                  type="radio"
                  name="preferenciaPais"
                  value="No"
                  checked={formData.preferenciaPais === 'No'}
                  onChange={handleChange}
                />
                No
              </label>
            </div>
          </div>

          {/* País Preferido */}
          {formData.preferenciaPais === 'Si' && (
            <div className="form-group">
              <select
                name="paisPreferido"
                value={formData.paisPreferido}
                onChange={handleChange}
                className="form-control"
                required
              >
                <option value="">Seleccione un país</option>
                {paises.map((pais, index) => (
                  <option key={index} value={pais}>{pais}</option>
                ))}
              </select>
            </div>
          )}

          {/* Tiempo de Inicio */}
          <div className="form-group">
            <select
              name="tiempoInicio"
              value={formData.tiempoInicio}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">¿Cuándo desearía iniciar el proceso?</option>
              {tiemposInicio.map((tiempo, index) => (
                <option key={index} value={tiempo}>{tiempo}</option>
              ))}
            </select>
          </div>

          {/* Cómo nos conoció */}
          <div className="form-group">
            <input
              type="text"
              name="comoNosConocio"
              value={formData.comoNosConocio}
              onChange={handleChange}
              className="form-control"
              placeholder="¿Cómo nos ha conocido?"
            />
          </div>

          {/* Observaciones */}
          <div className="form-group">
            <textarea
              name="observaciones"
              value={formData.observaciones}
              onChange={handleChange}
              className="form-control"
              placeholder="Observaciones y comentarios"
            />
          </div>

          {/* Política de Datos */}
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              name="politicaDatos"
              required
              checked={formData.politicaDatos}
              onChange={handleChange}
            />
            <label>
              He leído y acepto la Cláusula de información sobre protección de datos. 
              Autorizo el tratamiento de mis datos para recibir información por medios electrónicos.
            </label>
          </div>

          {/* Botón de Envío */}
          <div className="form-group submit-group">
            <button type="submit" className="submit-button">
              Enviar Solicitud
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GestlifeForm;
