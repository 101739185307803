// FormStyle3.jsx Formulario de Conferencias
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Función para obtener los parámetros UTM de la URL
const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_campaign: params.get('utm_campaign') || '',
    utm_term: params.get('utm_term') || '',
    utm_content: params.get('utm_content') || '',
  };
};

// Función para obtener la dirección IP del usuario
const fetchUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || '';
  } catch (error) {
    console.error('Error obteniendo la IP:', error);
    return '';
  }
};

const FormStyle3 = () => {
  const { t, i18n } = useTranslation();

  // Estado para los campos ocultos adicionales
  const [additionalFields, setAdditionalFields] = useState({
    idioma: i18n.language || 'es', // Idioma actual
    urlOrigen: document.referrer || window.location.href, // URL de origen
    ipUsuario: '', // Se llenará posteriormente
    ...getUTMParameters(), // Parámetros UTM
  });

  // Definir los valores de los campos ocultos básicos
  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'conferencias',
    form_name_id: 'tu_form_name_id', // Reemplaza con el valor real
    campaign_origin_id: '',
    origin_id: '8',
    lead_type_id: '3',
    market_id: 'tu_marketType', // Reemplaza con el valor real
  };

  const [formData, setFormData] = useState({
    tipoForm: 'conferencias',
    subtipo: 'Parejas Heterosexuales',
    estadoCivil: '',
    nombreAsistente1: '',
    emailAsistente1: '',
    telefonoAsistente1: '',
    edadAsistente1: '',
    nombreAsistente2: '',
    emailAsistente2: '',
    telefonoAsistente2: '',
    edadAsistente2: '',
    motivo: '',
    pregunta: '',
    autoriza: false,
    ...hiddenFields, // Incluir los campos ocultos básicos
    ...additionalFields, // Incluir los campos ocultos adicionales
  });

  // Obtener la IP del usuario al montar el componente
  useEffect(() => {
    const getIP = async () => {
      const ip = await fetchUserIP();
      setAdditionalFields((prev) => ({ ...prev, ipUsuario: ip }));
      setFormData((prev) => ({ ...prev, ipUsuario: ip }));
    };
    getIP();
  }, []);

  // Actualizar el idioma si cambia
  useEffect(() => {
    setAdditionalFields((prev) => ({ ...prev, idioma: i18n.language }));
    setFormData((prev) => ({ ...prev, idioma: i18n.language }));
  }, [i18n.language]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    const { name } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construir los datos para enviar en formato correcto
    const data = new URLSearchParams();

    // Añadir todos los campos del formulario, incluidos los ocultos
    for (const key in formData) {
      // Si el campo es un array, se añade múltiples veces
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item) => data.append(key, item));
      } else {
        data.append(key, formData[key]);
      }
    }

    // Enviar los datos al backend PHP
    try {
      const response = await fetch('https://dashboard.gestlifesurrogacy.com/formularios/formStyle3.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        // Manejar la respuesta exitosa
        console.log('Formulario enviado con éxito');
        // Opcional: Resetear el formulario o mostrar un mensaje al usuario
        setFormData({
          tipoForm: 'conferencias',
          subtipo: 'Parejas Heterosexuales',
          estadoCivil: '',
          nombreAsistente1: '',
          emailAsistente1: '',
          telefonoAsistente1: '',
          edadAsistente1: '',
          nombreAsistente2: '',
          emailAsistente2: '',
          telefonoAsistente2: '',
          edadAsistente2: '',
          motivo: '',
          pregunta: '',
          autoriza: false,
          ...hiddenFields, // Reiniciar los campos ocultos básicos
          ...additionalFields, // Reiniciar los campos ocultos adicionales
        });
      } else {
        // Manejar el error en la respuesta
        console.error('Error al enviar el formulario');
        // Opcional: Guardar los datos en el servidor o notificar al usuario
      }
    } catch (error) {
      console.error('Error de red:', error);
      // Opcional: Implementar una lógica de respaldo aquí
    }
  };

  return (
    <form onSubmit={handleSubmit} className="miFormulario">
      {/* Campos ocultos básicos */}
      {Object.keys(hiddenFields).map((key) => (
        <input key={key} type="hidden" name={key} value={hiddenFields[key]} />
      ))}

      {/* Campos ocultos adicionales */}
      {Object.keys(additionalFields).map((key) => (
        <input key={key} type="hidden" name={key} value={additionalFields[key]} />
      ))}

      <div className="row mt-4">
        <div className="col-md-6 col-12">
          <h4>{t('Asistente 1')}</h4>
          <div className="form-group">
            <label><b>{t('Nombre y apellidos')}</b></label>
            <input
              type="text"
              className="form-control"
              name="nombreAsistente1"
              value={formData.nombreAsistente1}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label><b>{t('Email')}</b></label>
            <input
              type="email"
              className="form-control"
              name="emailAsistente1"
              value={formData.emailAsistente1}
              onChange={handleChange}
              required
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label><b>{t('Teléfono')}</b></label>
                <PhoneInput
                  country={'es'}
                  value={formData.telefonoAsistente1}
                  onChange={(value, country, e, formattedValue) => handlePhoneChange(value, country, e, formattedValue)}
                  inputProps={{
                    name: 'telefonoAsistente1',
                    required: true,
                    className: 'form-control',
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label><b>{t('Edad')}</b></label>
                <input
                  type="number"
                  className="form-control"
                  name="edadAsistente1"
                  value={formData.edadAsistente1}
                  onChange={handleChange}
                  min="18"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12">
          <h4>{t('Asistente 2')}</h4>
          <div className="form-group">
            <label><b>{t('Nombre y apellidos')}</b></label>
            <input
              type="text"
              className="form-control"
              name="nombreAsistente2"
              value={formData.nombreAsistente2}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label><b>{t('Email')}</b></label>
            <input
              type="email"
              className="form-control"
              name="emailAsistente2"
              value={formData.emailAsistente2}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label><b>{t('Teléfono')}</b></label>
                <PhoneInput
                  country={'es'}
                  value={formData.telefonoAsistente2}
                  onChange={(value, country, e, formattedValue) => handlePhoneChange(value, country, e, formattedValue)}
                  inputProps={{
                    name: 'telefonoAsistente2',
                    className: 'form-control',
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label><b>{t('Edad')}</b></label>
                <input
                  type="number"
                  className="form-control"
                  name="edadAsistente2"
                  value={formData.edadAsistente2}
                  onChange={handleChange}
                  min="18"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label><b>{t('¿Motivo que les ha impedido ser padres hasta el momento?')}</b></label>
            <textarea
              name="motivo"
              className="form-control"
              style={{ resize: 'none' }}
              rows="5"
              value={formData.motivo}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label><b>{t('¿Desea usted explicarnos su caso para de forma anónima abordarlo durante la conferencia en el turno de preguntas?')}</b></label>
            <textarea
              name="pregunta"
              className="form-control"
              style={{ resize: 'none' }}
              rows="4"
              value={formData.pregunta}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="autoriza"
              checked={formData.autoriza}
              onChange={handleChange}
              required
              id="autoriza"
            />
            <label className="form-check-label" htmlFor="autoriza">
              {t('He leído y acepto la Cláusula de información sobre protección de datos. Autorizo al tratamiento de mis datos para poder recibir información por medios electrónicos.')} <a href="condiciones-generales-gestlife-gestacion-subrogada.php" target="_blank" rel="noopener noreferrer">{t('Ver información básica')}</a>
            </label>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <small>
            <b>{t('Aviso de Grabación:')}</b> <br />
            {t('Por favor, ten en cuenta que esta conferencia será grabada en video y/o audio con fines promocionales, educativos y de documentación. Al participar en la conferencia, das tu consentimiento para que tu imagen y voz puedan ser capturadas y utilizadas con estos propósitos.')} <br />
            {t('Si no deseas ser grabado/a, por favor, abstente de participar en preguntas o intervenciones que puedan incluir tu imagen o voz durante la sesión. Entendemos y respetamos la privacidad de todos los participantes, y cualquier solicitud de no ser grabado/a será respetada.')} <br />
            {t('Si tienes preguntas o inquietudes sobre el uso de las grabaciones, por favor, contacta a contacto@gestlifesurrogacy.com')} <br />
            {t('Gracias por tu comprensión y participación.')}
          </small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-4 offset-md-4 text-center">
          <button type="submit" style={{ backgroundColor: '#DA00C0' }} className="btn btn-custom w-100 text-uppercase mt-2 miBoton">
            {t('Enviar')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormStyle3;
